import React from "react";

import PropTypes from "prop-types";

import "./c-t-a1.css";

const CTA1 = (props) => {
  return (
    <div className={`c-t-a1-container ${props.rootClassName} `}>
      <div className="c-t-a1-container1">
        <h1 className="c-t-a1-text">{props.heading}</h1>
        <span className="c-t-a1-c-t-a-subtitle">{props.subtitle}</span>
      </div>
    </div>
  );
};

CTA1.defaultProps = {
  subtitle: "Subtitle",
  heading: "Heading",
  rootClassName: ""
};

CTA1.propTypes = {
  subtitle: PropTypes.string,
  heading: PropTypes.string,
  rootClassName: PropTypes.string
};

export default CTA1;
