import React from "react";

import PropTypes from "prop-types";

import "./feature-card4.css";

const FeatureCard4 = (props) => {
  console.log(props.ImprovementData);
  return (
    <div className={`feature-card4-feature-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        loading="lazy"
        className="feature-card4-image"
      />
      <h2 className="feature-card4-text">{props.title}</h2>
      {/* <span className="feature-card4-text1">{props.description}</span> */}
      <span
        className="feature-card4-text1"
        dangerouslySetInnerHTML={{ __html: props.improvementData }}
      ></span>
      <button className="feature-card4-button button">{props.button}</button>
    </div>
  );
};

FeatureCard4.defaultProps = {
  rootClassName: "",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.",
  button: "Fix Now",
  image_alt: "image",
  title: "Lorem ipsum",
  image_src: "https://play.teleporthq.io/static/svg/default-img.svg"
};

FeatureCard4.propTypes = {
  rootClassName: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  image_alt: PropTypes.string,
  title: PropTypes.string,
  image_src: PropTypes.string
};

export default FeatureCard4;
