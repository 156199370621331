import React from "react";

import PropTypes from "prop-types";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./feature-card1.css";

const FeatureCard1 = (props) => {
  const { accountRating } = props;
  return (
    <div className="feature-card1-feature-card">
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbarWithChildren
          value={accountRating * 10}
          // text={`${accountRating * 10}`}
          strokeWidth={12}
          styles={buildStyles({
            textColor: "black",
            pathColor: "rgb(2, 224, 153)"
          })}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <p style={{ fontSize: "60px", fontWeight: "bold" }}>
              {accountRating * 10}
            </p>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>fair</p>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <br />
      <h2 className="feature-card1-text">{props.heading}</h2>
      <span className="feature-card1-text1">{props.text}</span>
    </div>
  );
};

FeatureCard1.defaultProps = {
  accountRating: 6,
  heading: "Lorem ipsum",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum."
};

FeatureCard1.propTypes = {
  accountRating: PropTypes.number,
  heading: PropTypes.string,
  text: PropTypes.string
};

export default FeatureCard1;
