import React from "react";

import PropTypes from "prop-types";

import Logo from "./logo";
import CTA1 from "./c-t-a1";
import PrimaryButton from "./primary-button";
import "./style3.css";

const Style3 = (props) => {
  return (
    <div data-role="Header" className={`style3-style1 ${props.rootClassName} `}>
      <header className="style3-header">
        <div className="style3-container">
          <Logo className="" image_src={props.logo}></Logo>
          <CTA1
            rootClassName="c-t-a1-root-class-name"
            className=""
            heading={props.title}
            subtitle={props.subtitle}
          ></CTA1>
          <div className="style3-container1">
            <div className="style3-store-banner">
              <PrimaryButton
                buttonText={props.buttonText}
                hostingUrl={props.hostingUrl}
                buttonColor={props.buttonColor}
                className=""
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Style3.defaultProps = {
  rootClassName: ""
};

Style3.propTypes = {
  rootClassName: PropTypes.string
};

export default Style3;
