import React from "react";
import ServiceBell from "@servicebell/widget";

import PropTypes from "prop-types";

import "./primary-button.css";

const PrimaryButton = (props) => {
  console.log(props);

  return (
    <div className="primary-button-container">
      <button
        className="primary-button-button mediumLabel button"
        style={{ backgroundColor: props.buttonColor }}
        onClick={() => ServiceBell.dial()}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

PrimaryButton.defaultProps = {
  buttonText: "Get Started",
  buttonColor: "Button"
};

PrimaryButton.propTypes = {
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string
};

export default PrimaryButton;
