import React from "react";

import Style1 from "../components/style1";
import Style2 from "../components/style2";
import Style3 from "../components/style3";
import "./banner.css";

const Banner = (props) => {
  const {
    componentStyle,
    logo,
    title,
    subtitle,
    hostingUrl,
    buttonColor,
    buttonText
  } = props;
  console.log(buttonColor);
  return (
    <div className="banner-container">
      {componentStyle == "style1" && (
        <Style1
          rootClassName="style1-root-class-name"
          logo={logo}
          title={title}
          subtitle={subtitle}
          hostingUrl={hostingUrl}
          buttonColor={buttonColor}
          buttonText={buttonText}
        ></Style1>
      )}
      {componentStyle == "style2" && (
        <Style2
          rootClassName="style2-root-class-name"
          logo={logo}
          title={title}
          subtitle={subtitle}
          hostingUrl={hostingUrl}
          buttonColor={buttonColor}
          buttonText={buttonText}
        ></Style2>
      )}
      {componentStyle == "style3" && (
        <Style3
          rootClassName="style3-root-class-name"
          logo={logo}
          title={title}
          subtitle={subtitle}
          hostingUrl={hostingUrl}
          buttonColor={buttonColor}
          buttonText={buttonText}
        ></Style3>
      )}
    </div>
  );
};

export default Banner;
