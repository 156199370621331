import React from "react";

import PropTypes from "prop-types";

import Logo from "./logo";
import CTA from "./c-t-a";
import PrimaryButton from "./primary-button";
import "./style1.css";

const Style1 = (props) => {
  return (
    <div data-role="Header" className={`style1-style1 ${props.rootClassName} `}>
      <header className="style1-header">
        <Logo className="" image_src={props.logo}></Logo>
        <CTA
          rootClassName="c-t-a-root-class-name"
          className=""
          heading={props.title}
          subtitle={props.subtitle}
        ></CTA>
        <div className="style1-container">
          <div className="style1-store-banner">
            <PrimaryButton
              buttonText={props.buttonText}
              buttonColor={props.buttonColor}
              hostingUrl={props.hostingUrl}
              className=""
            ></PrimaryButton>
          </div>
        </div>
      </header>
    </div>
  );
};

Style1.propTypes = {
  rootClassName: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string
};

export default Style1;
