import { useState } from "react";
import "./style4.css";
import Style3 from "./style3";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
const Style4 = (props) => {
  const [open, setOpen] = useState(false);

  function toggleFab() {
    setOpen(!open);
  }
  return (
    <div class="fabs">
      <div class={open ? "chat is-visible" : "chat"}>
        <div class="chat_header">
          <div class="chat_option">
            <div class="header_img"></div>

            <span id="chat_fullscreen_loader" class="chat_fullscreen_loader">
              <i class="fullscreen zmdi zmdi-window-maximize"></i>
            </span>
          </div>
        </div>

        <div id="chat_body" class="chat_body">
          <Style3
            logo={props.logo}
            title={props.title}
            subtitle={props.subtitle}
            buttonColor={props.buttonColor}
            buttonText={props.buttonText}
          />
        </div>
      </div>
      <a
        id="prime"
        class={open ? "fab is-visible" : "fab"}
        style={{ background: `${props.buttonColor}` }}
        onClick={() => toggleFab()}
      >
        <i
          class={
            open ? " zmdi-close is-active is-visible" : " zmdi-comment-outline"
          }
        >
          <PlayCircleFilledWhiteIcon />
        </i>
      </a>
    </div>
  );
};

export default Style4;
