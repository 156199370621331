import React from "react";

import PropTypes from "prop-types";

import "./logo.css";

const Logo = (props) => {
  return (
    <div className="logo-logo">
      <img alt={props.image_alt} src={props.image_src} className="logo-image" />
    </div>
  );
};

Logo.defaultProps = {
  image_alt: "image",
  image_src:
    "https://www.broadplace.com/wp-content/uploads/2020/05/broadplace-logo-no-strap-line-480x160.png"
};

Logo.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string
};

export default Logo;
