import React from "react";

import PropTypes from "prop-types";

import "./c-t-a.css";

const CTA = (props) => {
  return (
    <div className={`c-t-a-container ${props.rootClassName} `}>
      <div className="c-t-a-container1">
        <h1 className="c-t-a-text">{props.heading}</h1>
        <span className="c-t-a-c-t-a-subtitle">{props.subtitle}</span>
      </div>
    </div>
  );
};

CTA.defaultProps = {
  rootClassName: "",
  heading: "Heading",
  subtitle: "Subtitle"
};

CTA.propTypes = {
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
  subtitle: PropTypes.string
};

export default CTA;
